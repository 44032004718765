import React from 'react';
import { Link } from 'react-router-dom';
import '../css/AboutUs.css';

const AboutUs: React.FC = () => {
  return (
    <div className="aboutus">
      <h1 className="aboutus__title">ABOUT US</h1>
      <p className="aboutus__description">ここに会社概要の内容を記載します。</p>

      <div className="aboutus__section">
        <h2 className="aboutus__section-title">Momela</h2>
        <p className="aboutus__section-description">
          8BINGOが運営するファッションのポータルサイト。
          数多のメディアが存在する今日において、ファッションメディアが果たすべき役割とは何か。
          この問いかけを続けながら、Momelaはファッションと社会を接続させていきます。
        </p>
      </div>

      <div className="aboutus__section">
        <h2 className="aboutus__section-title">8BINGO</h2>
        <p className="aboutus__section-description">
          Momelaを運営する会社です。
        </p>
      </div>

      {/* <div className="aboutus__section">
        <h2 className="aboutus__section-title">MEMBER</h2>
        <ul className="aboutus__list">
          <li className="aboutus__list-item">ようすけ</li>
          <li className="aboutus__list-item">とんきゅう</li>
          <li className="aboutus__list-item">かずま</li>
        </ul>
      </div> */}

      <div className="aboutus__section">
        <h2 className="aboutus__section-title">各種情報</h2>
        <ul className="aboutus__links">
          <li className="aboutus__link-item">
            <Link to="/privacy-policy">プライバシーポリシー</Link>
          </li>
          <li className="aboutus__link-item">
            <Link to="/disclaimer">免責事項</Link>
          </li>
          <li className="aboutus__link-item">
            <Link to="/contact">お問い合わせ</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
