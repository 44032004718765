import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../css/CreatePost.css';
import { MAX_IMAGE_COUNT } from '../utils/Constant';
import API from '../utils/API/API';
import Modal from './Modal';
import heic2any from 'heic2any';
import { saveToDB, getFromDB } from '../utils/db';

interface Category {
  id: number;
  name: string;
}

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Item {
  id: number;
  brand: Brand;
  name: string;
  url: string;
  price: number;
  image: string;
  categories: Category[];
}

interface FormDataType {
  comment: string;
  items: number[];
  images: File[];
}

interface SavedDataType {
  formData: FormDataType;
  selectedItems: Item[];
  imagePreviews: string[];
}

const CreatePost: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dbName = 'CreatePostDB';
  const storeName = 'formDataStore';
  const initialState = {
    comment: '',
    items: [] as number[],
    images: [] as File[],
  };
  const [formData, setFormData] = useState<FormDataType>(initialState);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [imageError, setImageError] = useState<string | null>(null);

  const [queryCategoryName, setQueryCategoryName] = useState<string | null>(
    location.state?.categoryName || null,
  );
  const [queryCategoryId, setQueryCategoryId] = useState<number | null>(
    location.state?.categoryId || null,
  );

  const [queryBrandName, setQueryBrandName] = useState<string | null>(
    location.state?.brandName || null,
  );
  const [queryBrandId, setQueryBrandId] = useState<number | null>(
    location.state?.brandId || null,
  );

  const [showItems, setShowItems] = useState(false);
  const [availableItems, setAvailableItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [searchItemTerm, setSearchItemTerm] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [Error, setError] = useState<string | null>(null);
  const [selectionError, setSelectionError] = useState<string | null>(null);

  useEffect(() => {
    setQueryCategoryId(location.state?.categoryId || null);
    setQueryCategoryName(location.state?.categoryName || null);
    setQueryBrandId(location.state?.brandId || null);
    setQueryBrandName(location.state?.brandName || null);
  }, [location]);

  useEffect(() => {
    const loadFromDB = async () => {
      const savedData = await getFromDB<SavedDataType>(
        dbName,
        storeName,
        'formData',
      );
      console.log('Loaded data:', savedData);
      if (savedData) {
        setFormData(savedData.formData);
        setSelectedItems(savedData.selectedItems || []);
        setImagePreviews(savedData.imagePreviews || []);
      }
    };
    loadFromDB();
  }, []);

  const handleSaveAndNavigate = async (path: string) => {
    console.log('Saving data before navigation...');
    try {
      await saveToDB(dbName, storeName, {
        id: 'formData',
        formData,
        selectedItems,
        imagePreviews,
      });
      console.log('Data saved successfully');
      console.log(selectedItems);
      console.log(imagePreviews);
      navigate(path, { state: { ...location.state } });
    } catch (error) {
      console.error('Failed to save data:', error);
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        let response;
        if (queryCategoryId && queryBrandId) {
          response = await API().get(
            `/api/items/?category=${queryCategoryId}&brand=${queryBrandId}`,
          );
        }
        if (response) {
          setAvailableItems(response.data.results);
        }
      } catch (error) {
        console.error('Failed to fetch items:', error);
      }
    };

    fetchItems();
  }, [queryCategoryId, queryBrandId]);

  useEffect(() => {
    setFilteredItems(
      availableItems.filter((item) =>
        item.name.toLowerCase().includes(searchItemTerm.toLowerCase()),
      ),
    );
  }, [searchItemTerm, availableItems]);

  useEffect(() => {
    if (imagePreviews.length > 0) {
      setImageError(null);
    }
  }, [imagePreviews]);

  const handleCategoryClick = () => {
    handleSaveAndNavigate('categories');
  };

  const handleBrandClick = () => {
    handleSaveAndNavigate('brands');
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    saveToDB(dbName, storeName, {
      id: 'formData',
      formData,
      selectedItems,
      imagePreviews,
    });
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files); // 複数ファイルを配列に変換
      const totalFiles = formData.images.length + files.length;

      // ファイル数がMAX_IMAGE_COUNTを超える場合はエラーメッセージを表示
      if (totalFiles > MAX_IMAGE_COUNT) {
        setImageError(`写真は${MAX_IMAGE_COUNT}枚まで追加できます`);
        return;
      }
      setImageError(null);

      const updatedPreviews: string[] = [];
      const updatedFiles: File[] = [];

      const readFileAsDataURL = (file: File) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      for (const file of files) {
        if (file.type === 'image/heic') {
          try {
            const convertedBlob = await heic2any({
              blob: file,
              toType: 'image/jpeg',
            });
            const convertedFile = new File(
              [convertedBlob],
              file.name.replace(/\.heic$/i, '.jpg'),
              {
                type: 'image/jpeg',
                lastModified: new Date().getTime(),
              },
            );
            const preview = await readFileAsDataURL(convertedFile as File);
            updatedPreviews.push(preview);
            updatedFiles.push(convertedFile);
          } catch (error) {
            console.error('HEICファイルの変換に失敗しました: ', error);
            setError('HEICファイルの変換に失敗しました');
          }
        } else if (file.type === 'image/avif') {
          try {
            const canvas = document.createElement('canvas');
            const img = new Image();
            img.src = URL.createObjectURL(file);

            await new Promise<void>((resolve, reject) => {
              img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                  ctx.drawImage(img, 0, 0);
                  canvas.toBlob((blob) => {
                    if (blob) {
                      const convertedFile = new File(
                        [blob],
                        file.name.replace(/\.avif$/i, '.jpg'),
                        {
                          type: 'image/jpeg',
                          lastModified: new Date().getTime(),
                        },
                      );
                      updatedFiles.push(convertedFile);
                      resolve();
                    } else {
                      setError('AVIFファイルの変換に失敗しました');
                    }
                  }, 'image/jpeg');
                } else {
                  setError('Canvasのコンテキストが取得できませんでした');
                }
              };

              img.onerror = reject;
            });

            const preview = await readFileAsDataURL(
              updatedFiles[updatedFiles.length - 1],
            );
            updatedPreviews.push(preview);
          } catch (error) {
            console.error('AVIFファイルの変換に失敗しました: ', error);
            setError('AVIFファイルの変換に失敗しました');
          }
        } else {
          try {
            const preview = await readFileAsDataURL(file);
            updatedPreviews.push(preview);
            updatedFiles.push(file);
          } catch (error) {
            console.error('画像の読み込みに失敗しました: ', error);
            setError('この画像はアップロードできません');
          }
        }
      }

      setImagePreviews((prevPreviews) => [...prevPreviews, ...updatedPreviews]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...updatedFiles],
      }));
      saveToDB(dbName, storeName, {
        id: 'formData',
        formData,
        selectedItems,
        imagePreviews,
      });
    }
  };

  const handleImageRemove = (index: number) => {
    setSelectedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    setFormData((prevFormData) => ({
      ...prevFormData,
      items: prevFormData.items.filter((_, i) => i !== index),
    }));

    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index),
    );
  };

  const handleItemChange = (itemId: number) => {
    const selectedItem = availableItems.find((item) => item.id === itemId);
    if (selectedItem) {
      if (selectedItems.some((item) => item.id === itemId)) {
        setError('すでに追加しています');
        return;
      }
      setSelectedItems((prevItems) => {
        const newItems = [...prevItems, selectedItem];
        console.log('Updated selected items:', newItems);
        return newItems;
      });
      setFormData((prevFormData) => {
        const newFormData = {
          ...prevFormData,
          items: [...prevFormData.items, itemId],
        };
        console.log('Updated form data:', newFormData);
        return newFormData;
      });
      setError(null);
    }
    saveToDB(dbName, storeName, {
      id: 'formData',
      formData,
      selectedItems,
      imagePreviews,
    });
  };

  const handleAddNewItemClick = () => {
    setQueryCategoryId(null);
    setQueryCategoryName(null);
    setQueryBrandId(null);
    setQueryBrandName(null);
    setAvailableItems([]);
  };

  const onCloseItemModal = () => {
    setSearchItemTerm('');
    setShowItems(false);
  };

  const handleItemRemove = (index: number) => {
    setSelectedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    setFormData((prevFormData) => ({
      ...prevFormData,
      items: prevFormData.items.filter((_, i) => i !== index),
    }));
    saveToDB(dbName, storeName, {
      id: 'formData',
      formData,
      selectedItems,
      imagePreviews,
    });
  };

  const handleShowItemsClick = () => {
    if (!queryCategoryId && !queryBrandId) {
      setSelectionError('カテゴリとブランドが選択されていません');
    } else if (!queryCategoryId) {
      setSelectionError('カテゴリが選択されていません');
    } else if (!queryBrandId) {
      setSelectionError('ブランドが選択されていません');
    } else {
      setSelectionError(null);
      setShowItems(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setImageError(null);
    await saveToDB(dbName, storeName, {
      id: 'formData',
      formData,
      selectedItems,
      imagePreviews,
    });
    navigate('/create/post/preview');
  };

  return (
    <>
      <div className="edit-profile-container">
        <h2 className="create-post__title">投稿新規作成</h2>

        {/* 写真部分 */}
        <form onSubmit={handleSubmit} className="create-post__form">
          <div className="create-post__form-group">
            <label htmlFor="icon" className="create-post__label">
              写真
            </label>

            <div className="create-post__input-container">
              {/* 選択写真プレビュー */}
              {imagePreviews.map((image, index) => (
                <div
                  key={index}
                  className="create-post__image-preview-container"
                >
                  <img
                    src={image}
                    alt={`Preview ${index}`}
                    className="create-post__image"
                  />
                  <button
                    type="button"
                    className="create-post__remove-image-button"
                    onClick={() => handleImageRemove(index)}
                  >
                    x
                  </button>
                </div>
              ))}
              {[...Array(5 - imagePreviews.length)].map((_, index) => (
                <div
                  key={`empty-${index}`}
                  className="create-post__image-preview-container create-post__image-preview-empty"
                >
                  {/* 空の枠、スタイルだけで内容はなし */}
                </div>
              ))}
            </div>

            <label htmlFor="icon" className="create-post__submit-button">
              写真を追加
              <input
                type="file"
                id="icon"
                name="images"
                onChange={handleImageChange}
                multiple
              />
            </label>
          </div>
          {imageError && (
            <p className="create-post__error-message">{imageError}</p>
          )}

          {/* コメント欄 */}
          <div>
            <label className="create-post__label">コメント</label>
            <textarea
              id="comment"
              name="comment"
              value={formData.comment}
              placeholder="コメントを追加"
              onChange={handleChange}
              className="create-post__textarea"
            />
          </div>

          {/* アイテム選択 */}
          <div>
            <label className="create-post__label">アイテム</label>

            {selectedItems.length > 0 && (
              <div>
                {selectedItems.map((item, index) => (
                  <div key={index} className="create-post__category-list-item">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="createpost__item-image"
                    />
                    <div className="createpost__item-info">
                      <p className="createpost__item-name">{item.name}</p>
                      <p className="createpost__item-brand">
                        {item.brand.name}
                      </p>
                      {item.categories.map((cat) => (
                        <p key={cat.id} className="createpost__item-category">
                          {cat.name}
                        </p>
                      ))}
                    </div>
                    <button
                      type="button"
                      className="remove-item-button"
                      onClick={() => handleItemRemove(index)}
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>
            )}

            <div className="create-post__form-group">
              <button
                type="button"
                onClick={handleAddNewItemClick}
                className="create-post__submit-button"
              >
                別のアイテムを追加
              </button>
            </div>

            {/* カテゴリ選択 */}
            <div
              onClick={handleCategoryClick}
              className="create-post__selected-category"
              role="button"
              tabIndex={0}
            >
              <span>
                {queryCategoryId
                  ? `${queryCategoryName || '取得中...'}`
                  : 'カテゴリーを選択'}
              </span>
              <span className="create-post__change-category-button">→</span>
            </div>

            {/* ブランド選択 */}
            <div
              onClick={handleBrandClick}
              className="create-post__selected-category"
              role="button"
              tabIndex={0}
            >
              <span>
                {queryBrandId
                  ? `${queryBrandName || '取得中...'}`
                  : 'ブランドを選択'}
              </span>
              <span className="create-post__change-category-button">→</span>
            </div>

            {/* アイテム選択ボタン */}
            <div className="create-post__item-input-container">
              <button
                type="button"
                onClick={handleShowItemsClick}
                className="create-post__submit-button"
                style={{
                  backgroundColor:
                    queryCategoryId && queryBrandId ? '#0095f6' : 'gray',
                  cursor:
                    queryCategoryId && queryBrandId ? 'pointer' : 'not-allowed',
                }}
                // disabled={!queryCategoryId || !queryBrandId}
              >
                アイテムを選択
              </button>
              {selectionError && (
                <p className="create-post__error-message">{selectionError}</p>
              )}
            </div>
            {Error && <p className="create-post__error-message">{Error}</p>}
          </div>

          <button type="submit" className="create-post__submit-button">
            プレビュー
          </button>
        </form>
        <Link to="/my-page" className="create-post__back-link">
          Back
        </Link>
      </div>

      {showItems && (
        <Modal isOpen={showItems} onClose={onCloseItemModal}>
          <button
            className="create-post__close-button"
            onClick={onCloseItemModal}
          >
            ←
          </button>

          <div className="create-post__selected-category">
            {queryCategoryName}
          </div>
          <div className="create-post__selected-category">{queryBrandName}</div>
          <h3 className="search-modal__category-select-title">
            指定された条件からアイテムを選択
          </h3>
          <div className="search-modal__input-container">
            <svg
              className="search-modal__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
              ></path>
            </svg>
            <input
              type="text"
              placeholder="検索"
              value={searchItemTerm}
              onChange={(e) => setSearchItemTerm(e.target.value)}
              className="search-modal__input"
            />
          </div>

          <ul>
            {filteredItems.map((item) => (
              <li key={item.id}>
                <button
                  type="button"
                  onClick={() => {
                    handleItemChange(item.id);
                    onCloseItemModal();
                  }}
                  className="createpost__item-list"
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    className="createpost__item-image"
                  />
                  <div className="createpost__item-info">
                    <p className="createpost__item-name">{item.name}</p>
                    <p className="createpost__item-brand">{item.brand.name}</p>
                    {item.categories.map((cat) => (
                      <p key={cat.id} className="createpost__item-category">
                        {cat.name}
                      </p>
                    ))}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </>
  );
};

export default CreatePost;
