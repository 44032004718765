import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthAPI from '../utils/API/AuthAPI';
import '../css/PreviewPost.css';
import { getFromDB, clearIndexedDB } from '../utils/db';

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Item {
  id: number;
  brand: Brand;
  name: string;
  url: string;
  price: number;
  image: string;
}

interface FormDataType {
  comment: string;
  items: number[];
  images: File[];
}

interface SavedDataType {
  formData: FormDataType;
  selectedItems: Item[];
  imagePreviews: string[];
}

const PreviewPost: React.FC = () => {
  const navigate = useNavigate();
  const dbName = 'CreatePostDB';
  const storeName = 'formDataStore';
  const [formData, setFormData] = useState<FormDataType | null>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);

  useEffect(() => {
    const loadFromIndexedDB = async () => {
      const savedData = await getFromDB<SavedDataType>(
        dbName,
        storeName,
        'formData',
      );
      if (savedData) {
        setFormData(savedData.formData);
        setSelectedItems(savedData.selectedItems || []);
        setImagePreviews(savedData.imagePreviews || []);
        console.log('saved', savedData);
      } else {
        navigate('/create/post');
      }
    };

    loadFromIndexedDB();
  }, [navigate]);

  const handlePost = () => {
    if (!formData) return;
    const formDataToSend = new FormData();
    formDataToSend.append('comment', formData.comment);
    formData.items.forEach((item: number) =>
      formDataToSend.append('items', String(item)),
    );
    formData.images.forEach((image: File, index: number) => {
      formDataToSend.append(`images[${index}]image`, image);
    });

    AuthAPI()
      .post('api/posts/', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async () => {
        await clearIndexedDB(dbName, storeName, 'formData');
        navigate('/');
      })
      .catch((error) => {
        console.error('failed to post', error);
      });
  };

  return (
    <div className="preview-post">
      <h2 className="preview-post__title">プレビュー</h2>

      <div className="preview-post__section">
        <label className="preview-post__label">写真:</label>
        <div className="preview-post__images">
          {imagePreviews.map((image: string, index: number) => (
            <img
              key={index}
              src={image}
              alt={`Preview ${index}`}
              className="preview-post__image"
            />
          ))}
        </div>
      </div>

      <div className="preview-post__section">
        <label className="preview-post__label">コメント:</label>
        <p className="preview-post__comment">{formData?.comment}</p>
      </div>

      <div className="preview-post__section">
        <label className="preview-post__label">アイテム:</label>
        <ul className="preview-post__items">
          {selectedItems.length > 0 ? (
            selectedItems.map((item: Item) => (
              <li key={item.id} className="preview-post__item">
                {item.name}
              </li>
            ))
          ) : (
            <p>No items selected</p>
          )}
        </ul>
      </div>

      <button className="preview-post__submit-button" onClick={handlePost}>
        投稿
      </button>
      <button
        className="preview-post__back-button"
        onClick={() =>
          navigate('/create/post', {
            state: { formData, imagePreviews, selectedItems },
          })
        }
      >
        戻る
      </button>
    </div>
  );
};

export default PreviewPost;
