import React, { useState, useEffect } from 'react';
import '../css/Home.css';
import { useNavigate } from 'react-router-dom';
import API from '../utils/API/API';
import Loading from './Loading';
import GridPosts from './GridPosts';
import { DEFAULT_BRAND_ICON } from '../utils/Constant';
import useSWRInfinite from 'swr/infinite';
import { AxiosError } from 'axios';

interface Brand {
  id: number;
  name: string;
  url: string;
  icon: string | null;
}

interface Model {
  bio: string;
  height: number;
  icon: string | null;
  instagram: string;
}

interface Post {
  id: number;
  comment: string;
  images: string[];
  username: string;
  modelinfo: Model;
}

interface ApiResponse {
  results: Post[];
}

const Home: React.FC = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [isEndReached, setIsEndReached] = useState(false);
  const navigate = useNavigate();

  const fetcher: (url: string) => Promise<ApiResponse> = async (
    url: string,
  ) => {
    try {
      const response = await API().get(url);
      return response.data;
    } catch (error: unknown) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const responseData = axiosError.response.data as { detail?: string };
        if (
          axiosError.response.status === 404 ||
          responseData?.detail === 'Invalid page.'
        ) {
          setHasMore(false);
          setIsEndReached(true);
          return { endOfData: true };
        }
      }

      throw error;
    }
  };

  const getKey = (
    pageIndex: number,
    previousPageData: ApiResponse | { endOfData: boolean } | null,
  ) => {
    if (
      previousPageData &&
      ('endOfData' in previousPageData || previousPageData.results.length === 0)
    ) {
      setHasMore(false);
      setIsEndReached(true);
      return null;
    }

    return `api/posts/?page=${pageIndex + 1}&page_size=30&order=recent`; // 次のページのURL
  };

  const { data, error, size, setSize, isValidating } = useSWRInfinite<
    ApiResponse,
    AxiosError
  >(getKey, fetcher);

  const recentPosts =
    data && data.length > 0
      ? data.flatMap((pageData) => pageData?.results || [])
      : [];

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const brandsResponse = await API().get('api/pickup/brands/?num=10');
        setBrands(brandsResponse.data.brands || []);
      } catch {
        setBrands([]);
      } finally {
        setLoadingBrands(false);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isEndReached) return;

      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 500 &&
        !isValidating &&
        hasMore
      ) {
        setSize(size + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [size, setSize, isValidating, hasMore, isEndReached]);

  if (loadingBrands && !data && error) {
    return <Loading />;
  }

  return (
    <div className="home__container">
      <div className="home__brands">
        {brands.length > 0 ? (
          <div
            className="home__brands__slider"
            style={{
              overflowX: 'scroll',
              whiteSpace: 'nowrap',
              padding: '10px',
            }}
          >
            {brands.map((brand) => (
              <div
                key={brand.id}
                className="home__brand-box"
                onClick={() => navigate(`/brand/${brand.id}`)}
              >
                <img
                  src={brand.icon || DEFAULT_BRAND_ICON}
                  alt={`${brand.name} icon`}
                  className="home__brand-icon"
                />
                <span className="home__brand-name">{brand.name}</span>
              </div>
            ))}
          </div>
        ) : (
          <p>No brands available.</p>
        )}
        <a href="brandlist" className="home__allbrands">
          すべてのブランドを見る ＞
        </a>
      </div>

      <div className="home__tab-content">
        <GridPosts posts={recentPosts} />
        {/* {isValidating? (<GridPosts posts={recentPosts} />) : (<Loading />) } */}
      </div>
    </div>
  );
};

export default Home;
